<template>
  <div class="wrap">
    <div class="wrap-content">
      <v-container fluid>

        <v-row align="center" justify="center" class="text-center">
          <v-col cols="12" lg="8">

            <v-layout justify-center align-center>
              <v-flex shrink>
                <v-img width="90" :src="require('@/assets/logo2.png')"
                ></v-img>
              </v-flex>
            </v-layout>

            <v-layout justify-center align-center class="mt-3">
              <v-flex shrink>
                <v-img :src="require('@/assets/gifty-scan.webp')"
                       width="280"
                ></v-img>
              </v-flex>
            </v-layout>


            <h1 class="welcome-title font-arabic g-color text-no-wrap mt-5">
              كونيكتي وابدأ
              <span class="gifty-text">
               تلعب  !
            </span>
            </h1>

            <v-btn @click="dialog = true"
                   color="cyan"
                   :block="$vuetify.breakpoint.mobile"
                   class="mt-2 font-arabic"
                  
                   x-large>
              جرب حضك
            </v-btn>

            <h3 class="mt-5 font-arabic g-color mt-10">
              حتى الان راه رابح معانا
            </h3>
            <h3 class="font-arabic gifty-text d-flex align-center justify-center mt-2">
              🎉
              {{ animatedNumber }} فائز
            </h3>

          </v-col>
        </v-row>

        <v-dialog v-model="dialog" width="400">
          <v-card>
            <v-card-text class="pa-10">

              <h2 class="font-arabic g-color text-center">
                عندك تطبيق <span class="gifty-text">GIFTY</span> في تيليفونك !
              </h2>

              <div class="text-center mt-5">
                <a href="https://onelink.to/bj77mv" target="_blank">
                  <v-btn depressed
                         large
                         outlined
                         class="rounded-lg font-arabic"
                         color="cyan">
                    <strong>
                      معنديش
                    </strong>
                  </v-btn>
                </a>

                &nbsp;
                <v-btn color="gifty"
                       @click="[$refs.startDialog.open(),dialog = false]"
                       depressed
                       class="rounded-lg font-arabic"
                       large
                       dark>
                  <strong>
                    عندي
                  </strong>
                </v-btn>
              </div>

              <div class="d-flex align-center justify-center mt-10">

                <a href="https://play.google.com/store/apps/details?id=com.gifty.pro.android" target="_blank">
                  <v-img width="95"
                         class="cursor-pointer"
                         :src="require('@/assets/play.png')"

                  ></v-img>
                </a>

                <a href="https://apps.apple.com/dz/app/gifty-dz/id6451093253?l=fr-FR" target="_blank">
                  <v-img width="95"
                         class="cursor-pointer mx-2"
                         :src="require('@/assets/store.png')"

                  ></v-img>
                </a>

                <a href="https://appgallery.huawei.com/#/app/C108042577" target="_blank">
                  <v-img width="95"
                         class="cursor-pointer"
                         :src="require('@/assets/gallery.png')"
                  ></v-img>
                </a>
              </div>

            </v-card-text>
          </v-card>
        </v-dialog>

        <StartDialog ref="startDialog"/>

      </v-container>
    </div>
  </div>
</template>

<script>
import StartDialog from './components/StartDialog'
import axios from "axios";

export default {
  components: {
    StartDialog
  },
  data() {
    return {
      isLoading: false,
      dialog: false,
      animatedNumber: 0,
      statistic: {
        used: 0
      },
    }
  },
  methods: {
    async getStatistic() {
      this.isLoading = true;
      try {
        let res = await axios.get(process.env.VUE_APP_BASE_URL + "gifty-games/statistic");

        this.statistic = res.data.data;

      } catch (err) {
        console.log(err)
      } finally {
        this.isLoading = false;
      }
    },
    animateNumber(start, end, duration) {
      const startTime = performance.now();

      const animate = (currentTime) => {
        const elapsedTime = currentTime - startTime;
        const progress = Math.min(elapsedTime / duration, 1);
        this.animatedNumber = Math.floor(progress * (end - start) + start);
        if (progress < 1) {
          requestAnimationFrame(animate);
        }
      };

      requestAnimationFrame(animate);
    },
  },
  mounted() {
    this.animateNumber(0, this.statistic.used, 2000);
  },
  watch: {
    'statistic.used'(newValue) {
      this.animateNumber(this.animatedNumber, newValue, 2000);
    },
  },
  created() {
    this.getStatistic()
  }
}
</script>

<style>
@media only screen and (max-width: 600px) {
  html, body, #app {
    overflow-x: hidden;
    margin: 0;
    padding: 0;
  }
}

.wrap {
  position: relative;
}

.wrap:before {
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  opacity: 0.4;
  background-image: url('../../assets/bg-04.jpg');
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
}

.wrap-content {
  position: relative;
}


.g-color {
  color: #262646;
}

.welcome-title {
  font-size: 50px;
}

.welcome-subtitle {
  font-size: 20px;
  color: #3e3d3d;
}

@media (max-width: 768px) {
  .welcome-title {
    font-size: 30px;
  }

  .welcome-subtitle {
    font-size: 17px;
  }
}

.row {
  margin: 0;
}

.font-arabic {
  font-family: 'Tajawal', sans-serif;
  direction: rtl;
}
</style>
