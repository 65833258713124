<template>
  <div>
    <v-dialog v-model="dialog"
              fullscreen
              hide-overlay
              open-on-focus
              transition="dialog-bottom-transition">

      <v-card tile>
        <v-card-text class="pa-0 mb-lg-4 mb-0">
          <div class="wrap">
            <div class="wrap-content">
              <v-row justify="center" no-gutters>
                <v-col cols="12" lg="6">

                  <div class="px-4 pt-4">
                    <v-btn color="gifty" class="font-arabic" text @click="dialog = false">
                      رجوع
                      <v-icon left>mdi-arrow-left</v-icon>
                    </v-btn>
                  </div>

                  <div class="text-center">

                    <v-layout justify-center align-center>
                      <v-flex shrink>

                        <v-img v-if="step === 'one'"
                               :src="require('@/assets/game-login.webp')"
                               width="220"
                        ></v-img>

                        <v-img v-if="step === 'two'"
                               :src="require('@/assets/start-game.webp')"
                               width="220"
                        ></v-img>

                      </v-flex>
                    </v-layout>
                  </div>

                  <div v-if="step === 'one'" class="px-8">

                    <v-alert color="error" class="font-arabic" text v-if="error_401">
                      رقم الهاتف غير صحيح أو كلمة المرور غير صحيحة. الرجاء المحاولة مرة أخرى.
                    </v-alert>


                    <p class="font-weight-bold g-color font-arabic">
                      رقم الهاتف
                      <span class="red--text">*</span>
                    </p>

                    <v-text-field placeholder="رقم الهاتف"
                                  color="gifty"
                                  background-color="white"
                                  flat
                                  solo
                                  outlined
                                  v-model="phone"
                                  type="number"
                                  counter="10"
                                  :error-messages="errors.phone">
                    </v-text-field>

                    <p class="font-weight-bold g-color font-arabic">
                      كلمة السر
                      <span class="red--text">*</span>
                    </p>
                    <v-text-field color="gifty"
                                  placeholder="كلمة السر"
                                  outlined
                                  flat
                                  solo
                                  v-model="password"
                                  :error-messages="errors.password"
                                  :type="showPassword ? 'text' : 'password'"
                                  append-icon="mdi-eye"
                                  @click:append="showPassword = !showPassword">
                      <template v-slot:append>
                        <v-icon color="gifty" @click="showPassword = !showPassword">
                          {{ showPassword ? 'mdi-eye-off' : 'mdi-eye' }}
                        </v-icon>
                      </template>
                    </v-text-field>

                    <div class="text-center">
                      <v-btn color="cyan"
                             class="font-arabic"
                             dark
                             :block="$vuetify.breakpoint.mobile"
                             x-large
                             @click="handleAuth"
                             :loading="isLoading">
                        تسجيل الدخول
                      </v-btn>
                    </div>
                  </div>

                  <div v-else>

                    <div class="text-center px-8">

                      <div class="d-flex justify-center" :style="{marginTop: '-30px'}">
                        <lottie :options="successOptions" :width="130"/>
                      </div>

                      <h2 class="font-arabic g-color" :style="{marginTop: '-30px'}">
                        تم تسجيل الدخول بنجاح
                      </h2>


                      <h1 class="font-arabic gifty-text mb-3 mt-8"
                          v-if="todayParticipationCount < 3 && !won">
                        نبداو نلعبو؟
                      </h1>

                      <h3 class="font-arabic gifty-text mb-3 mt-8" v-else>
                        <span v-if="won && won.amount > 0">
                             🎉 تهانينا! لقد ربحت {{ won.amount }} Pts
                        </span>
                        <span v-else>
                            عذراً، لقد استنفدت جميع محاولاتك لهذا اليوم. حاول مرة أخرى غداً!
                          </span>
                      </h3>

                      <v-btn color="cyan"
                             class="font-arabic"
                             dark
                             :block="$vuetify.breakpoint.mobile"
                             x-large
                             v-if="todayParticipationCount < 3 && !won"
                             @click="startGame"
                             :loading="isLoading">
                        نلعب
                      </v-btn>

                    </div>

                    <div v-if="start_game" class="robo-bg">
                      <div v-if="!show_game_result && !isLoading">
                        <div class="text-center" :style="{ marginBottom: '-100px' }">
                          <img alt="" class="fade-in" width="180" :src="require('@/assets/robot1.webp')"/>
                        </div>
                        <div class="d-flex justify-center">
                          <lottie :options="defaultOptions" :width="500"/>
                        </div>
                      </div>

                      <div v-else>
                        <div v-if="win_amount === 0">
                          <div class="text-center" :style="{ marginBottom: '-100px' }">
                            <img alt="" class="fade-in" width="180" :src="require('@/assets/robot3.webp')"/>
                          </div>
                          <div class="d-flex justify-center">
                            <lottie :options="lostOptions" :width="500"/>
                          </div>
                          <v-row justify="center" v-if="todayParticipationCount < 3">
                            <v-col cols="8">
                              <div class="text-center" :style="{ marginTop: '-30px' }">
                                <h3 class="font-arabic white--text mb-3">
                                  هل ترغب في تجربة حظك مرة أخرى؟
                                </h3>
                                <v-btn color="gifty" dark @click="startGame" :loading="isLoading"
                                       x-large class="font-arabic">
                                    <span class="font-weight-bold" v-if="todayParticipationCount === 1">
                                           نعم، سأحاول مرة أخرى
                                    </span>
                                  <span class="font-weight-bold" v-if="todayParticipationCount === 2">
                                      المحاولة الأخيرة
                                    </span>
                                </v-btn>
                              </div>
                            </v-col>
                          </v-row>

                          <v-row justify="center" v-if="todayParticipationCount >= 3">
                            <v-col cols="8">
                              <div class="text-center" :style="{ marginTop: '-30px' }">
                                <h3 class="font-arabic white--text">
                                  عذراً، لقد استنفدت جميع محاولاتك لهذا اليوم. حاول مرة أخرى غداً!
                                </h3>
                              </div>
                            </v-col>
                          </v-row>
                        </div>

                        <div v-else class="bg-gif">
                          <div class="text-center" :style="{ marginBottom: '-100px' }">
                            <img class="fade-in" alt="" width="180" :src="require('@/assets/robot2.webp')"/>
                          </div>
                          <div class="d-flex justify-center">
                            <lottie :options="winOptions" :width="500"/>
                          </div>
                          <div class="text-center"
                               :style="{position: 'relative', top: '-220px',marginRight: '10px'}">
                            <h3 class="white--text">
                              {{ win_amount }}
                            </h3>
                          </div>
                          <v-row justify="center">
                            <v-col cols="8">
                              <div class="text-center" :style="{ marginTop: '-60px' }">
                                <h3 class="font-arabic white--text">
                                  🎉 تهانينا! لقد ربحت {{ win_amount }} نقطة وقد تم إرسالها إلى حسابك في GIFTY.
                                 </h3>
                              </div>
                            </v-col>
                          </v-row>
                        </div>
                      </div>
                    </div>


                  </div>

                </v-col>
              </v-row>
            </div>
          </div>
        </v-card-text>

      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import lottie from 'vue-lottie';
import gameJson from '@/assets/lottie/game.json';
import successJson from '@/assets/lottie/success.json';
import winJson from '@/assets/lottie/win.json';
import lostJson from '@/assets/lottie/lost.json';

export default {
  components: {
    lottie
  },
  data() {
    return {
      defaultOptions: {
        animationData: gameJson,
        loop: false,
        autoplay: true,
      },
      winOptions: {
        animationData: winJson,
        loop: false,
        autoplay: true,
      },
      successOptions: {
        animationData: successJson,
        loop: false,
        autoplay: true,
      },
      lostOptions: {
        animationData: lostJson,
        loop: false,
        autoplay: true,
      },

      step: 'one',
      dialog: false,
      error_401: false,
      showPassword: false,
      isLoading: false,
      password: null,
      phone: null,
      errors: {},
      user: {},
      todayParticipationCount: 0,
      start_game: false,
      won: {},

      show_game_result: false,
      win_amount: 0
    };
  },
  methods: {
    open() {
      this.errors = {}
      this.show_game_result = false
      this.win_amount = 0
      this.start_game = false
      this.dialog = true
      this.step = 'one'
      this.error_401 = false
    },
    async handleAuth() {
      this.isLoading = true;
      this.error_401 = false;

      try {
        const res = await axios.post(process.env.VUE_APP_BASE_URL + "gifty-games/auth", {
          phone: this.phone?.replace(/^0/, '+213'),
          password: this.password
        });

        this.step = 'two'
        this.user = res.data.user
        this.todayParticipationCount = res.data.today_participation_count
        this.won = res.data.won

      } catch (err) {

        this.isLoading = false;

        if (err.response) {

          if (err.response.status === 422) {
            this.errors = err.response.data.errors;
          }

          if (err.response.status === 401) {
            this.error_401 = true;
          }

        }

      } finally {
        this.isLoading = false;
      }
    },
    async startGame() {
      this.isLoading = true;
      this.show_game_result = false

      try {
        let res = await axios.post(process.env.VUE_APP_BASE_URL + "gifty-games/start", {
          phone: this.phone?.replace(/^0/, '+213'),
          password: this.password
        });

        this.start_game = true;
        this.todayParticipationCount += 1;

        let _vm = this
        setTimeout(function () {
          _vm.win_amount = res.data.win_amount
          _vm.show_game_result = true
        }, 8000)

      } catch (err) {

        this.isLoading = false;

        if (err.response) {

          if (err.response.status === 422) {
            this.errors = err.response.data.errors;
          }

          if (err.response.status === 429) {
            this.dialog = false;
          }
        }

      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>

<style scoped>

.robo-bg {
  background: linear-gradient(135deg, #3D22BD, #262646);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in {
  animation: fadeIn 0.5s ease-in forwards;
}

.bg-gif {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: url("../../../assets/confetti.gif") no-repeat center center;
  background-size: cover;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

</style>
